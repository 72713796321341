<!-- RightSIdebar -->
<template>
  <div class="right-sidebar">
    <!-- Contenido del Right Sidebar -->
    <div class="vertical-text">
      <h2 @click="goToSection('work')">Work</h2>
      <h2 @click="goToSection('bio')">Bio</h2>
      <h2 @click="goToFooter">Contact</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightSidebar',
  methods: {
    goToSection(section) {
      if (section === 'work') {
        this.$router.push({ name: 'Home' });
        this.scrollToElement('#first-video-preview');
      } else if (section === 'bio') {
        this.$router.push({ name: 'Bio' });
      }
    },
    goToFooter() {
      this.$nextTick(() => {
        const footer = document.querySelector('footer');
        if (footer) {
          footer.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    scrollToElement(selector) {
      this.$nextTick(() => {
        const element = document.querySelector(selector);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }
};
</script>

<style scoped>
.right-sidebar {
  width: 20px;
  background-color: #00000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: fixed;
  right: 0;
  z-index: 1000;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.vertical-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  color: rgb(255, 255, 255);
  height: 100%;
  transform: rotate(180deg);
  white-space: nowrap;
}

.vertical-text h2 {
  margin-bottom: 170px;
  writing-mode: vertical-rl;
}
</style>
